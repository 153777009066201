import React, { useState } from "react";
import { Form, Button, Container, Col, Row } from "react-bootstrap";

import * as styledComponents from "./forms.module.scss";

// markup
const ContactForm = () => {
  const [validated, setValidated] = useState(false);
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };
  return (
    <Container>
      <div className={styledComponents._form}>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <h1 className="mt-3 pt-3">Contactez-nous</h1>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Nom complet :</Form.Label>
            <Form.Control type="text" placeholder="Nom Prenom" required />
            <Form.Control.Feedback />
            <Form.Control.Feedback type="invalid">
              Veuillez écrire votre nom
            </Form.Control.Feedback>
          </Form.Group>
          <Row>
            <Form.Group
              className="mb-3"
              controlId="formBasicEmail"
              as={Col}
              md="6"
            >
              <Form.Label>Téléphone :</Form.Label>
              <Form.Control type="tel" placeholder="514 555 5555" required />
              <Form.Control.Feedback />
              <Form.Control.Feedback type="invalid">
                Veuillez écrire votre téléphone
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="formBasicEmail"
              as={Col}
              md="6"
            >
              <Form.Label>Courriel :</Form.Label>
              <Form.Control
                type="email"
                placeholder="courriel@example.com"
                required
              />
              <Form.Control.Feedback />
              <Form.Control.Feedback type="invalid">
                Veuillez fournir une adresse valide
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Message :</Form.Label>
            <Form.Control as="textarea" rows={3} />
          </Form.Group>
          <Button variant="primary" type="submit" className="mb-3">
            Envoyer
          </Button>
        </Form>
      </div>
    </Container>
  );
};
export default ContactForm;
