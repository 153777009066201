import * as React from "react";
import * as styledComponents from "./card.module.scss";
import { Card } from "react-bootstrap";

// markup
const CardServiceComponent = ({ image }) => {
  return (
    <Card className={styledComponents._cardService}>
      <img src={image} alt="" style={{ width: "40px", textAlign: "center" }} />
      <Card.Body>
        <Card.Title>2021 Nissan Rogue</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">
          MIDNIGHT EDITION
        </Card.Subtitle>
        <Card.Text>
          <p className="text-muted">62,827 km</p>
        </Card.Text>
      </Card.Body>
      <Card.Footer
        className="text-center"
        style={{ background: "green", color: "white" }}
      >
        283 498 FCFA
      </Card.Footer>
    </Card>
  );
};

export default CardServiceComponent;
