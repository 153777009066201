import * as React from "react";
import NavBar from "./navbar";
import Footer from "./footer/footer";

// markup
const LayoutComponent = ({ children }) => {
  return (
    <>
      <NavBar />
      {children}
      <Footer />
    </>
  );
};
export default LayoutComponent;
