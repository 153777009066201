import * as React from "react";
import { Container } from "react-bootstrap";

import CardServiceComponent from "../components/card/cardservice";
import LayoutComponent from "../components/layout";
import ContactForm from "../components/forms/contactform";
import * as styledComponents from "../styles/exportation.module.scss";

import containersvg from "../images/container.svg";

// markup
const Pieces = () => {
  return (
    <LayoutComponent>
      <Container className="mt-3">
        <div className={styledComponents.hero}>
          <div className={styledComponents.herotext}>
            <h1 className="mb-5">
              <b>L'exportation au bout des doigts</b>
            </h1>
            <p>
              <b>
                Du Canada vers toutes les destinations du monde, expédiez en
                toute simplicité vos marchandises, véhicules & effets
                personnels, cargaisons commerciales. On s’occupe de tout.
                #Particuliers #Professionnels
              </b>
            </p>
          </div>
          <div className={styledComponents.heroimage}></div>
        </div>
        <section className={styledComponents.section_1}>
          <div className={styledComponents.section_1text}>
            <h2>Gagnez du temps et faites des économies&nbsp;! </h2>
            <p>
              Nous accompagnons les particuliers et professionnels a acheminer
              depuis le Canada leur cargaison à travers le monde en toute
              sécurité. On propose des tarifs intéressants accompagnés de
              conseils personnalisés sur le choix de la solution la mieux
              adaptée: déménagement, envoi de marchandises, envoi de colis,
              transport de voiture, transport de moto, cargaisons commerciales,
              produits à risque etc. Du ramassage à l’arrimage de votre
              marchandise, du camionnage de votre conteneur à l’émission du
              connaissement, nous nous occupons d’absolument tout.
              #expeditiondeconteneur #entreprosage #servicedetransit #douane
            </p>
          </div>
          <div className={styledComponents.section_1image}></div>
        </section>
        <section className={styledComponents.services}>
          <h3>Nos services</h3>
          <CardServiceComponent image={containersvg} />
          <h6>Expédition de conteneurs</h6>
          <p>
            Envoyez vos véhicules et effets personnels, déménagez à
            l'international, transportez des marchandises commerciales.
          </p>
        </section>
      </Container>
      <ContactForm />
    </LayoutComponent>
  );
};

export default Pieces;
