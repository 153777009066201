import * as React from "react";
import { Container } from "react-bootstrap";
import Link from "../link";

// markup
const Footer = () => {
  return (
    <footer
      style={{
        background: "#333333",
        padding: "0.5em 0",
        bottom: "0",
        width: "100%",
        textAlign: "center",
        marginTop: "40px",
      }}
    >
      <Container>
        <Link to="https://heliotvilchis.com/">
          <h5>© 2021 Heliot Vilchis. Tous droits réservés</h5>
        </Link>
      </Container>
    </footer>
  );
};

export default Footer;
